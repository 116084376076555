import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { BulletinService } from '../core/bulletin.service';
import { BulletinRec } from '../data';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss']
})
export class BulletinComponent implements OnInit {

  isLoading: boolean;
  bulletin: BulletinRec = {} as BulletinRec;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bulletinSrv: BulletinService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.getBulletin(+params.get('id'));
    });
  }

  async getBulletin(id: number) {
    this.bulletin = await this.bulletinSrv.getBulletin(id) as BulletinRec;
  }

  goBack() {
    this.router.navigate(['/bulletins']);
  }

  async reomveBulletin() {
    await this.bulletinSrv.removeBulletin(this.bulletin.id);
    this.goBack();
  }

}
