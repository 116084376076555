<div id="bbSubscribe" class="pt-3" page>
    <!-- <section class="page-title">
        <div class="container d-flex align-items-center">
            <button class="btn btn-light mb-0 mr-3 btn-back"
                [routerLink]="['/']">
                <i class="material-icons mr-1">arrow_back_ios</i>
            </button>
            <h3>校園官網訂閱</h3>
        </div>
    </section> -->
    <div class="modal-body sub-modal">
        <button class="btn btn-light mb-3 btn-back" style="width: 96px;background: #f3f3f3;" [routerLink]="['/']">
            <i class="material-icons mr-1" style="font-size: 20px; vertical-align: middle;">arrow_back_ios</i>返回
        </button>
        <ul class="nav nav-justified nav-tabs mb-3">
            <li class="nav-item">
                <a class="nav-link" style="line-height: 1.2;"
                    href="javascript:void(0)"
                    [ngClass]="{'active': tab==='subscribed'}"
                    (click)="setCurrentTab('subscribed')">已訂閱<br>頻道</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="line-height: 1.2;"
                    href="javascript:void(0)"
                    [ngClass]="{'active': tab==='education'}"
                    (click)="setCurrentTab('education')">臺北市<br>教育局</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="line-height: 1.2;"
                    href="javascript:void(0)"
                    [ngClass]="{'active': tab==='school'}"
                    (click)="setCurrentTab('school')">學校<br>官網</a>
            </li>
        </ul>
        <div class="tab-content" [ngSwitch]="tab">
            <app-subscribed-channel *ngSwitchCase="'subscribed'"></app-subscribed-channel>
            <app-education-channel *ngSwitchCase="'education'"></app-education-channel>
            <app-school-channel *ngSwitchCase="'school'"></app-school-channel>
        </div>
    </div>
</div>



