<div id="bbPage" class="pt-3" page>
    <!-- <section class="page-title">
        <div class="container d-flex align-items-center">
            <button class="btn btn-light mb-0 mr-3 btn-back" [routerLink]="['/']">
                <i class="material-icons mr-1">arrow_back_ios</i>
            </button>
            <h3>校園官網訂閱</h3>
        </div>
    </section> -->
    <section class="container">
        <button class="btn btn-light mb-3 btn-back" style="width: 96px;background: #f3f3f3;" [routerLink]="['/']">
            <i class="material-icons mr-1" style="font-size: 20px; vertical-align: middle;">arrow_back_ios</i>返回
        </button>
        <h3 class="mb-3">{{bulletin.title}}</h3>
        <div class="dropdown-divider mb-3"></div>
        <small class="d-flex mb-3 text-black-secondary">
            <span class="mr-auto">{{bulletin.ann_time}}</span>
            <span>{{bulletin.organization_name}}</span>
        </small>
        <div>
            <div class="content" style="word-break: break-word; white-space: break-spaces; overflow-x: hidden;" [innerHTML]="bulletin.description | safeHtml"></div>
        </div>
        <div class="d-flex align-items-center mt-3">
            <a class="btn btn-info mr-auto" [href]="bulletin.link" target="_blank">
                <i class="material-icons">link</i>
                公告來源
            </a>
            <a class="btn btn-outline-danger" href="javascript:void(0)" (click)="reomveBulletin()">
                <i class="material-icons">delete</i>
                刪除
            </a>
        </div>
    </section>
</div>