import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlFilter'
})
export class HtmlFilterPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value
      .replace(/(<([^>]+)>)/ig, '')
      .replace(/&nbsp;/ig, '') // 換行
      .replace(/&quot;/ig, '') // "
      .slice(0, 120);
  }

}
