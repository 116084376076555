import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  content: string;
  isConfirm: boolean;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  yes() {
    this.bsModalRef.hide();
    this.isConfirm = true;
  }

  no() {
    this.bsModalRef.hide();
    this.isConfirm = false;
  }

}
