import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take, startWith } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ChannelRec, OrganizationRec } from '../../data';
import { Store, Select } from '@ngxs/store';
import { UnSubscribe } from '../../state/organization.action';

@Component({
  selector: 'app-subscribed-channel',
  templateUrl: './subscribed-channel.component.html',
  styleUrls: ['./subscribed-channel.component.scss']
})
export class SubscribedChannelComponent implements OnInit, OnDestroy {

  bsModalRef: BsModalRef;
  keyword = new FormControl(null);
  @Select(state => state.organizations) organizations$: Observable<OrganizationRec[]>;
  channelList: ChannelRec[] = [];
  filterChannels: ChannelRec[] = [];
  finish$ = new Subject();

  constructor(
    private bsModalSrv: BsModalService,
    private store: Store
  ) { }

  async ngOnInit() {
    // state
    this.organizations$.pipe(
      takeUntil(this.finish$)
    ).subscribe(v => {
      if (v) {
        this.getSubscribedChannels(v);
      }
    });
    // formcontrol
    this.keyword.valueChanges.pipe(
      takeUntil(this.finish$),
      startWith('')
    ).subscribe(v => {
      this.channelFilter(v)
    });
  }

  ngOnDestroy(): void {
    this.finish$.next();
    this.finish$.complete();
  }

  getSubscribedChannels(organizations: OrganizationRec[]) {
    this.channelList = [];
    organizations.forEach((org: OrganizationRec) => {
      this.channelList = this.channelList.concat(org.channels.filter((channelRec => channelRec.is_subscribed)));
    });
    this.keyword.setValue('');
  }

  async unsubscribeChannel(channel: ChannelRec) {
    this.bsModalSrv.onHide.pipe(
      take(1)
    ).subscribe(async () => {
      if (this.bsModalRef.content.isConfirm) {
        this.store.dispatch(new UnSubscribe(channel.id));
      }
    });

    this.bsModalRef = this.bsModalSrv.show(ConfirmDialogComponent, {
      initialState: { title: '訂閱提醒', content: `確定取消訂閱「${channel.title}」此頻道？`}
    });
  }

  channelFilter(key: string) {
    this.filterChannels = [];
    this.filterChannels = this.channelList.filter(chanRec => chanRec.title.includes(key) || chanRec.org_name.includes(key));
  }
}
