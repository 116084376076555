import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BulletinListComponent } from './bulletin-list/bulletin-list.component';
import { BulletinComponent } from './bulletin/bulletin.component';
import { SubscribeEditorComponent } from './subscribe-editor/subscribe-editor.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SubscribedChannelComponent } from './subscribe-editor/subscribed-channel/subscribed-channel.component';
import { EducationChannelComponent } from './subscribe-editor/education-channel/education-channel.component';
import { SchoolChannelComponent } from './subscribe-editor/school-channel/school-channel.component';
import { SafeHtmlPipe } from './bulletin/safe-html.pipe';
import { HtmlFilterPipe } from './bulletin-list/html-filter.pipe';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgxsModule } from '@ngxs/store';
import { OrganizationState } from './state/organization.state';

@NgModule({
  declarations: [
    AppComponent,
    BulletinListComponent,
    BulletinComponent,
    SubscribeEditorComponent,
    PageNotFoundComponent,
    SubscribedChannelComponent,
    EducationChannelComponent,
    SchoolChannelComponent,
    SafeHtmlPipe,
    HtmlFilterPipe,
    ConfirmDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxsModule.forRoot([OrganizationState])
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
