
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { GetOrganizations, Subscribe, UnSubscribe } from './organization.action';
import { BulletinService } from '../core/bulletin.service';
import { OrganizationRec, ChannelRec } from '../data';

@State({
    name: 'organizations',
    defaults: [] as OrganizationRec[]
})
@Injectable()
export class OrganizationState {

    constructor(
        private bulletinSrv: BulletinService
    ) { }

    @Action(GetOrganizations)
    async getOrganizations(ctx: StateContext<any>, action: GetOrganizations) {
        try {
            ctx.setState([].concat(await this.getEduChannel(), await this.getSchoolChannel()));
        } catch (error) {
            ctx.setState([]);
        }
    }

    /** 取得局端頻道清單 */
    async getEduChannel(): Promise<OrganizationRec[]> {
        const organizations: OrganizationRec[] = [];
        const rsp = await this.bulletinSrv.getChannels('edu');

        [].concat(rsp || []).forEach(data => {
            if (!organizations.find(org => org.name === data.org_name)) {
                organizations.push({
                    name: data.org_name,
                    type: 'edu',
                    channels: []
                });
            }
            organizations.find(org => org.name === data.org_name).channels.push({ ...data });
        });
        return organizations;
    }

    /** 取得校端頻道清單 */
    async getSchoolChannel(): Promise<OrganizationRec[]> {
        const schools: OrganizationRec[] = [];
        const rsp = await this.bulletinSrv.getChannels('school');
        [].concat(rsp || []).map(data => {
            if (!schools.find(school => school.schoolCode === data.school_code)) {
                schools.push({
                    name: data.school_name,
                    type: 'school',
                    schoolCode: data.school_code,
                    channels: []
                });
            }
            schools.find(school => school.schoolCode === data.school_code).channels.push({ ...data, org_name: data.school_name });
        });
        return schools;
    }

    @Action(Subscribe)
    async subscribeChannel(ctx: StateContext<any>, action: Subscribe) {
        try {
            await this.bulletinSrv.subscribeChannel(action.id);
            ctx.setState(this.subscribeChange(ctx.getState(), action.id, true));
        } catch (error) {
            ctx.setState(null);
            console.warn(error);
        }
    }

    @Action(UnSubscribe)
    async unsubscribeChannel(ctx: StateContext<any>, action: UnSubscribe) {
        try {
            await this.bulletinSrv.unsubscribeChannel(action.id);
            ctx.setState(this.subscribeChange(ctx.getState(), action.id, false));
        } catch (error) {
            ctx.setState(null);
            console.warn(error);
        }
    }

    subscribeChange(organizations: OrganizationRec[], id: number, state: boolean): OrganizationRec[] {
        return organizations.map(orgRec => {
            if (orgRec.channels.find((channelRec: ChannelRec) => channelRec.id === id)) {
                orgRec.channels.find((channelRec: ChannelRec) => channelRec.id === id).is_subscribed = state;
            }
            return orgRec;
        });
    }
}