import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { OrganizationRec, ChannelRec } from '../../data';
import { Store, Select } from '@ngxs/store';
import { Subscribe, UnSubscribe } from '../../state/organization.action';

@Component({
  selector: 'app-education-channel',
  templateUrl: './education-channel.component.html',
  styleUrls: ['./education-channel.component.scss']
})
export class EducationChannelComponent implements OnInit, OnDestroy {

  bsModalRef: BsModalRef;
  key: string = '';
  organizations: OrganizationRec[] = [];
  @Select(state => state.organizations)organizations$: Observable<OrganizationRec[]>;
  filterOrganizations: OrganizationRec[] = [];
  unsubscribe$ = new Subject();

  constructor(
    private bsModalSrv: BsModalService,
    private store: Store
  ) { }

  async ngOnInit() {
    this.organizations$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(v => {
      this.getEduChannel(v);
    });
    this.channelFilter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getEduChannel(organizations: OrganizationRec[]) {
    this.organizations = organizations.filter((orgRec: OrganizationRec) => orgRec.type === 'edu');
  }

  clickChannel(channel: ChannelRec) {
    if (channel.is_subscribed) {
      this.bsModalRef = this.bsModalSrv.show(ConfirmDialogComponent, {
        initialState: { title: '訂閱提醒', content: `確定取消訂閱「${channel.title}」此頻道？`}
      });

      this.bsModalSrv.onHide.pipe(
        take(1)
      ).subscribe(async () => {
        if (this.bsModalRef.content.isConfirm) {
          this.store.dispatch(new UnSubscribe(channel.id));
        }
      });
    } else {
      this.store.dispatch(new Subscribe(channel.id));
    }
  }

  channelFilter() {
    this.filterOrganizations = [];
    this.organizations.forEach((orgRec: OrganizationRec) => {
      orgRec.channels.forEach((channelRec: ChannelRec) => {
        if (channelRec.org_name.includes(this.key) || channelRec.title.includes(this.key)) {
          if (!this.filterOrganizations.find(org => org.name === channelRec.org_name)) {
            this.filterOrganizations.push({
              name: orgRec.name,
              type: orgRec.type,
              channels: []
            });
          }
          this.filterOrganizations.find(org => org.name === channelRec.org_name).channels.push(channelRec);
        }
      });
    });
  }

}
