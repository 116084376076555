<div id="bbList" class="pt-3" page>
    <!-- <section class="page-title">
        <div class="container">
            <h3 class="d-flex align-items-center">
                校園官網訂閱
                <small class="ml-1">({{unReadNumber}})</small>
            </h3>
        </div>
    </section> -->
    <section class="container">
        <div id="bbTool" class="d-flex flex-column flex-sm-row align-items-end mb-2rem">
            <button class="btn btn-cyan mb-3 mb-sm-0 order-sm-2"
                [routerLink]="['/subscribe']">訂閱設定</button>
            <input class="bb-form w-sm-75 mr-auto order-sm-1" type="text" 
                [formControl]="keyword"
                placeholder="校園公告查詢...">
        </div>
        <ul class="list-group list-group-flush my-3 bb-list" *ngIf="bulletinList.length>0;else tplNoData">
            <li class="list-group-item list-group-item-action d-flex" 
                [ngClass]="{'unread': !bulletin.is_read}"
                *ngFor="let bulletin of bulletinList"
                (click)="openBulletin(bulletin.id)">
                <div class="bb-area">{{bulletin.OrgFirstName}}</div>
                <div class="ml-3">
                    <div class="h5" [innerHTML]="bulletin.title | safeHtml"></div>
                    <div class="bb-text">{{bulletin.description | htmlFilter}}</div>
                    <small class="d-flex justify-content-between">
                        <span>{{bulletin.ann_time}}</span>
                        <span>{{bulletin.organization_name}}</span>
                    </small>
                </div>
            </li>
        </ul>
    </section>
</div>

<ng-template #tplNoData>
    <p class="text-center text-danger">{{noDataMsg}}</p>
</ng-template>
