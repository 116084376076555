import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BulletinService } from '../core/bulletin.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, tap, map } from 'rxjs/operators';
import { BulletinRec } from '../data';

@Component({
  selector: 'app-bulletin-list',
  templateUrl: './bulletin-list.component.html',
  styleUrls: ['./bulletin-list.component.scss']
})
export class BulletinListComponent implements OnInit, OnDestroy {

  finish$ = new Subject();
  isLoading: boolean;
  bulletinList: BulletinExRec[] = [];
  keyword = new FormControl('');
  unReadNumber: number;
  noDataMsg: string;

  constructor(
    private bulletinSrv: BulletinService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.geAllBulletins();
    this.keyword.valueChanges.pipe(
      takeUntil(this.finish$),
      map(v => v.trim()),
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => { this.search(); })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.finish$.next();
  }

  async geAllBulletins() {
    const rsp = await this.bulletinSrv.getAllBulletins();
    this.bulletinList = [].concat(rsp || []).map((data: any) => {
      // data.OrgFirstName = data.organization_name.split('')[0];
      data.OrgFirstName = data.type === '校端' ? '校' : '局';
      return data;
    });
    this.unReadNumber = this.bulletinList.filter(bulletin => !bulletin.is_read).length;
    this.noDataMsg = '查無公告，請至『訂閱設定』中選取欲關注的頻道。';
  }

  async openBulletin(id: number) {
    const rsp = await this.bulletinSrv.setBulletinIsRead(id);
    this.router.navigate(['/bulletin', id]);
  }

  async search() {
    const rsp = await this.bulletinSrv.searchBulletins(this.keyword.value);
    this.bulletinList = [].concat(rsp || []).map((data: any) => {
      // data.OrgFirstName = data.organization_name.split('')[0];
      data.OrgFirstName = data.type === '校端' ? '校' : '局';
      return data;
    });
    this.noDataMsg = '查無資料。';
  }

}

interface BulletinExRec extends BulletinRec {
  OrgFirstName: string;
}
