import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetOrganizations } from '../state/organization.action';

@Component({
  selector: 'app-subscribe-editor',
  templateUrl: './subscribe-editor.component.html',
  styleUrls: ['./subscribe-editor.component.scss']
})
export class SubscribeEditorComponent implements OnInit {

  tab: 'subscribed' | 'education' | 'school';

  constructor(
    private store: Store
  ) { }

  ngOnInit() {
    this.setCurrentTab('subscribed');
    this.store.dispatch(new GetOrganizations());
  }

  setCurrentTab(tab: 'subscribed' | 'education' | 'school') {
    this.tab = tab;
  }

}
