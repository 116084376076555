import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BulletinService {

  constructor(
    private http: HttpClient
  ) { }

  public async getTop5Bulletin() {
    return this.http.get('services/bulletin/get_top5_bulletins').toPromise();
  }

  public async getAllBulletins() {
    return this.http.get('services/bulletin/get_all_bulletins').toPromise();
  }

  public async setBulletinIsRead(annID: number) {
    return this.http.post('services/bulletin/set_bulletin_is_read', { ann_id: annID }).toPromise();
  }

  public async getBulletin(id: number) {
    return this.http.get(`services/bulletin/get_bulletin?id=${id}`).toPromise();
  }

  public async removeBulletin(id: number) {
    return this.http.post(`services/bulletin/remove_bulletin`, { id }).toPromise();
  }

  public async searchBulletins(keyword: string) {
    return this.http.post(`services/bulletin/search_bulletins`, { keyword }).toPromise();
  }

  public async getSubscription() {
    return this.http.get('services/bulletin/get_subscription').toPromise();
  }

  public async getChannels(type: string) {
    return this.http.get(`services/bulletin/get_channels?type=${type}`).toPromise();
  }

  public async subscribeChannel(id: number) {
    return this.http.post(`services/bulletin/subscribe_channel`, { id }).toPromise();
  }

  public async unsubscribeChannel(id: number) {
    return this.http.post(`services/bulletin/unsubscribe_channel`, { id }).toPromise();
  }

  public async searchSubscribedChannel(keyword: string) {
    return this.http.post(`services/bulletin/search_subscribe_channel`, { keyword }).toPromise();
  }

}
