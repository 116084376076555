<input class="bb-form my-3 mb-4" type="text" [(ngModel)]="key" (keyup.enter)="channelFilter()" placeholder="查詢...">
<div class="list-group">
    <div class="expansion-panel list-group-item" *ngFor="let orgRec of filterOrganizations">
        <a aria-controls="collapseOne" aria-expanded="false" 
            class="expansion-panel-toggler collapsed"
            data-toggle="collapse" [href]="'#' + orgRec.name">
            {{orgRec.name}}
            <div class="expansion-panel-icon ml-3 text-black-secondary">
                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
            </div>
        </a>
        <div class="collapse" [id]="orgRec.name">
            <ul class="expansion-panel-body list-group list-group-flush">
                <li class="list-group-item list-group-item-action"
                    *ngFor="let channel of orgRec.channels" (click)="clickChannel(channel)">
                    <div class="sub-check custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" style="cursor: pointer;" [checked]="channel.is_subscribed">
                        <label class="custom-control-label d-flex align-items-center" style="cursor: pointer;">
                            <span class="mr-auto">{{channel.title}}</span>
                            <!-- <small class="text-black-secondary">{{channel.org_name}}</small> -->
                        </label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>