<input class="bb-form my-3 mb-4" type="text" 
  [formControl]="keyword"
  placeholder="查詢...">
<ul class="expansion-panel-body list-group list-group-flush" *ngIf="filterChannels.length>0;else tplNoData">
  <li class="list-group-item list-group-item-action" *ngFor="let channel of filterChannels">
    <div class="sub-check custom-control custom-checkbox" (click)="unsubscribeChannel(channel)">
      <input type="checkbox" class="custom-control-input" checked style="cursor: pointer;">
      <label class="custom-control-label d-flex align-items-center" style="cursor: pointer;">
        <span class="mr-auto">{{channel.title}}</span>
        <small class="text-black-secondary">{{channel.org_name}}</small>
      </label>
    </div>
  </li>
</ul>
<ng-template #tplNoData>
  <p class="text-center text-danger">尚未訂閱頻道。</p>
</ng-template>