export class GetOrganizations {
    static readonly type = '[org] get organizations';
    constructor() {}
}

export class Subscribe {
    static readonly type = '[channel] subscribe channel';
    constructor(public id: number) {}
}

export class UnSubscribe {
    static readonly type = '[channel] unsubscribe channel';
    constructor(public id: number) {}
}