import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BulletinListComponent } from './bulletin-list/bulletin-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BulletinComponent } from './bulletin/bulletin.component';
import { SubscribeEditorComponent } from './subscribe-editor/subscribe-editor.component';
import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
  { path: 'bulletins', canActivate: [AuthGuard], component: BulletinListComponent },
  { path: 'bulletin/:id', component: BulletinComponent },
  { path: 'subscribe', component: SubscribeEditorComponent },
  { path: '', redirectTo: 'bulletins', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
