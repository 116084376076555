<!-- https://valor-software.com/ngx-bootstrap/#/modals#service-component -->
<!-- title & content 寫在 initialState 裡面就可以了 -->

<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="no()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{content}}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="no()">取消</button>
    <button type="button" class="btn btn-primary" (click)="yes()">確定</button>
</div>