import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { OrganizationRec, ChannelRec } from '../../data';
import { Store, Select } from '@ngxs/store';
import { Subscribe, UnSubscribe } from '../../state/organization.action';

@Component({
  selector: 'app-school-channel',
  templateUrl: './school-channel.component.html',
  styleUrls: ['./school-channel.component.scss']
})
export class SchoolChannelComponent implements OnInit, OnDestroy {

  bsModalRef: BsModalRef;
  finish$ = new Subject();
  key: string = '';
  @Select(state => state.organizations)organizations$: Observable<OrganizationRec[]>;
  schools: OrganizationRec[] = [];
  filterSchools: OrganizationRec[] = [];

  constructor(
    private bsModalSrv: BsModalService,
    private store: Store
  ) { }

  async ngOnInit() {
    this.organizations$.pipe(
      takeUntil(this.finish$)
    ).subscribe(v => {
      this.getSchoolChannels(v);
    });
    this.channelFilter();
  }

  ngOnDestroy(): void {
    this.finish$.next();
    this.finish$.complete();
  }

  getSchoolChannels(organizations: OrganizationRec[]) {
    this.schools = organizations.filter((orgRec: OrganizationRec) => orgRec.type === 'school');
  }

  clickChannel(channel: ChannelRec) {
    if (channel.is_subscribed) {
      this.unSubscribeChannel(channel);
    } else {
      this.store.dispatch(new Subscribe(channel.id));
    }
  }

  async unSubscribeChannel(channel: ChannelRec) {
    this.bsModalRef = this.bsModalSrv.show(ConfirmDialogComponent, {
      initialState: { title: '訂閱提醒', content: `確定取消訂閱「${channel.title}」此頻道？`}
    });

    this.bsModalSrv.onHide.pipe(
      take(1)
    ).subscribe(async () => {
      if (this.bsModalRef.content.isConfirm) {
        this.store.dispatch(new UnSubscribe(channel.id));
      }
    });
  }

  channelFilter() {
    this.filterSchools = [];
    this.schools.forEach((schoolRec: OrganizationRec) => {
      schoolRec.channels.forEach((channelRec: ChannelRec) => {
        if (channelRec.org_name.includes(this.key) || channelRec.title.includes(this.key)) {
          if (!this.filterSchools.find(sch => sch.schoolCode === schoolRec.schoolCode)) {
            this.filterSchools.push({
              name: schoolRec.name,
              type: schoolRec.type,
              schoolCode: schoolRec.schoolCode,
              channels: []
            });
          }
          this.filterSchools.find(sch => sch.schoolCode === schoolRec.schoolCode).channels.push(channelRec);
        }
      });
    });
  }

}